// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./assets/sass/style.react";

.cursor-default {
  cursor: default !important;
}

.btn {
  border-radius: unset !important;
}