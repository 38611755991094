.add-confirmation .MuiStepLabel-label.MuiStepLabel-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
    font-size: 12px;
}

.add-confirmation .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.add-confirmation .content {
    padding: 20px 200px !important;
}

.add-confirmation .content .stepper{
    margin: 20px -200px !important;
}

.add-confirmation label {
    margin-bottom: 0px !important;
}


.pr-50, pl-50 {
    padding: 100px !important;

}

.center-content {
    display: flex;
    justify-content: center;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.display-flex {
    display: flex !important;
    justify-content: space-between;
}