@media (min-width: 576px) {
    .container {
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 100%;
    }
}