.mzr-dev-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(red, 0.4);
}

.mzr-dev-banner label {
    font-size: 1.5rem;
}

.mzr-nav-header {
    background-color: #0071CE;
    color: #fff;
    font-family: "Arial", sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    flex-grow: 1;
    padding-left: 1rem;
}
