@import "../style.scss";

.MuiButtonBase-root {
  font-family: "Arial", sans-serif;
}

.avatar {
  margin: 2px;
  width: 30px;
  height: 30px;
}

.MuiToolbar-root {
  > div:nth-child(1) {
    order:2;
    text-indent:20px;
  }
  > div:nth-child(2) {
    order:1;
  }
  > div:nth-child(3) {
    order:3;
  }
  > div:nth-child(4) {
    order:4;
  }
}
.MuiTableRow-root {
  cursor: pointer;
}

.MuiToolbar-regular {
  margin: 15px 0;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #EBEDF3;
}

