.ExcelTable2007 {
    border: 1px solid #b0cbef;
    border-width: 1px 0px 0px 1px;
    font-size: 11pt;
    font-family: Helvetica;
    font-weight: 100;
    border-spacing: 0px;
    border-collapse: collapse;
    padding: 10px;
}

.ExcelTable2007 TH {
    background-image: url(excel-2007-header-bg.gif);
    background-repeat: repeat-x;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
    text-align: center;
}

.ExcelTable2007 TD {
    color: grey;
    background-color: white;
    padding: 0px 4px 0px 2px;
    border: 1px solid #d0d7e5;
    border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
    border: 0px;
    background-color: white;
    font-weight: bold;
}

.ExcelTable2007 TD.heading {
    background-color: #e4ecf7;
    text-align: center;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TH.heading {
    background-image: url(excel-2007-header-left.gif);
    background-repeat: none;
}

.restrict-card {
    height: 175px;
    overflow-y: overlay;
}
