.container {
    z-index: 1;
}

.DropDownList {
    margin: 0.8em 0 0;
    padding: 0;
    background: #ffffff;
    color: #3faffa;
    font-size: 1.25rem;
    list-style: none;
}

.ListItem {
    margin-bottom: 0.8em;
    list-style-type: none;
}

.ListItem:hover, .selected {
    background: #E1E9FF;
    cursor: pointer;
}

.ListItem:hover, .selected a {
    background: #E1E9FF;
    cursor: pointer;
}

.ListItem a {
    color: #181C32 !important;
    font-size: 1.25rem;
    padding-left: 3.5em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    width: auto;
    display: block;

}