.login {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
}

.login .container-fluid{
  display: table-cell;
  vertical-align: middle;
}

.login .container {
  margin-top: 15%;
  box-shadow: -2px 2px 5px 1px rgba(0, 0, 0, 0.39);
}

.login .col-md-5, .col-md-7 {
  padding-left: 0;
  padding-right: 0;
}

.login .jumbotron {
  background: #FFFFFF;
  height: 70%;
}

.login .text-center {
  margin-top: 2%;
}

.login .alert img {
  width: 20px;
  margin-top: -5px;
  margin-left: 5px;
}

.login .warning-error-hide {
  display: none;
}

.login .warning-error-active {
  margin-top: 1em;
  display: block;
}

.login .overlay {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(99, 156, 185, 0.92);
  opacity: 0.6;
}

.login .content-text {
  position: relative;
  padding: 0 30px;
  color: #fff;
  margin: auto;
}

.login .heading {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 26px;
  font-weight: 300;
}

.login .signin-logo {
  width: 100%;
}

.login .signin-logo img {
  width: 55%;
}

.login .alert-heading.h4 {
  font-size: 14px;
}

.login .btn-primary {
  background: #aa272f;
  border-color: #aa272f;
  cursor: pointer;
}

.login .sweet-loading {
  margin-left: 40%;
  margin-top: 20px;
}

.login .login-block{
  border-radius: 5px;

  height: 450px;
  margin: 0 auto;
  background-color: #fff;
}

@media only screen and (min-device-width: 480px) {
  .login .login-block {width: 25%;}
}
