.engagement-table .MuiIconButton-root {
    padding: 0.35rem !important;
}

.MuiTableCell-root {
    z-index: 0 !important;
}

.engagement-table .MuiTableCell-paddingNone:last-child div {
    display: flex !important;
    justify-content: center !important;
}

.filter .card-body {
    padding: 0rem 2.25rem !important;
}
